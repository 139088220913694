<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入跳转地址" suffix-icon="el-icon-search" v-model="jumpUrl"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="200"></el-table-column>
      <el-table-column width="200" label="图片" prop="imageUrl">
        <template slot-scope="scope">
          <img :src="scope.row.imageUrl" alt="图片" style="width:100px;height: 100px;object-fit: cover;" />
        </template>
      </el-table-column>
      <el-table-column prop="jumpUrl" label="跳转地址"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="visible" label="前台是否可见">
        <template slot-scope="scope">
          <span>{{ scope.row.visible ? '是' : '否' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm class="ml-5" confirm-button-text='确定' cancel-button-text='我再想想' icon="el-icon-info"
            icon-color="red" title="您确定删除吗？" @confirm="del(scope.row.id)">
            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div style="padding: 10px 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
        :page-sizes="[2, 5, 10, 20]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div> -->

    <el-dialog title="文件类型" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false"
      @closed="onClosed">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" label-suffix=":" size="small"
        style="width: 90%">

        <el-form-item label="banner图片" prop="imageUrl">
          <el-upload ref="imgUpload" action="#" :file-list="fileList" list-type="picture-card" :auto-upload="true"
            :limit="1" :show-file-list="true" :on-change="onImgChange" :on-remove="onImgRemove"
            :http-request="httpRequest" accept=".jpeg,.jpg,.png" :before-upload="beforeUpload">
            <el-button class="upload-btn ml-5" type="primary">上传 <i class="el-icon-top"></i></el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpeg/jpg/png文件，图片尺寸建议350*300px</div>
            <!-- <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
            </div> -->
          </el-upload>
        </el-form-item>

        <el-form-item label="跳转地址" prop="jumpUrl">
          <el-input v-model="form.jumpUrl" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input v-model.number="form.sort" autocomplete="off"></el-input>
        </el-form-item>


        <el-form-item label="前台是否可见" prop="visible">
          <el-switch v-model="form.visible" active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "Course",
  data() {
    return {
      jumpUrl: '',
      fileList: [],
      accept: [
        '.jpeg',
        '.jpg',
        '.png'
      ],
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      form: {
        id: '',
        visible: false,
        sort: '',
        jumpUrl: '',
        imageUrl: ''
      },
      rules: {
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
        ],
        // jumpUrl: [
        //   { required: true, message: '请输入跳转地址', trigger: 'blur' },
        // ],
        imageUrl: [
          { required: true, message: '请选择封面图片', trigger: 'change' },
        ],
        visible: [
          { required: true, message: '请选择前台是否可见', trigger: 'change' },
        ],
      },
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    async httpRequest(options) {
      const elFile = this.$refs.imgUpload.uploadFiles.find((item) => item.uid == options.file.uid);
      console.log("httpRequest elFile:", elFile)
      let formData = new FormData()
      formData.append("file", options.file)
      let { code, data } = await this.request({
        url: '/file/upload',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData
      });
      if (code == 0) {
        elFile.status = 'success';
        elFile.url = data;
        this.form.imageUrl = data;
        console.log("this.form:", this.form)

        this.$refs.form.validateField('imageUrl')
        console.log("elFile.url:", elFile.url)
      } else {
        elFile.status = 'fail';
      }
    },

    getImageSize(file) {
      return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          resolve({
            width: img.width,
            height: img.height
          })
        };
        img.error = function* () {
          resolve({
            width: 0,
            height: 0
          })
        }
        img.src = _URL.createObjectURL(file);
      })

    },

    beforeUpload(file) {
      return new Promise(async (resolve, reject) => {
        if (!this.validateFileType(file)) {
          reject();
          return
        }
        //限制尺寸
        // const { width, height } = await this.getImageSize(file)
        // console.log("beforeUpload width:", width, " height:", height)
        // let isSize = (width == 350 && height == 300) ? true : false
        // if (!isSize) {
        //   this.$message({
        //     message: "上传banner图片尺寸为350*300",
        //     type: "warning",
        //   })
        //   reject();
        // }
        resolve(file);
      })
    },
    validateFileType(file) {
      if (this.accept.length === 0) {
        return true
      }
      let suffix = file.name.substring(file.name.lastIndexOf("."))
      console.log("suffix:", suffix)
      const extension = this.accept.includes(suffix)
      if (!extension) {
        this.$message({
          message: "上传文件只能是" + this.accept.toString() + "格式!",
          type: "warning",
        })
      }
      return extension
    },
    onImgChange(file, fileList) {
      console.log("onImgChange fileList:", fileList)
    },
    onImgRemove(file, fileList) {
      if (!fileList.length) {
        this.form.imageUrl = '';
        console.log("this.form:", this.form)
        this.$refs.form.validateField('imageUrl')
      }
    },
    load() {
      this.request.get("/banner/all", {
        params: {
          "jumpUrl": this.jumpUrl,
          "page": this.pageNum,
          "limit": this.pageSize
        }
      }).then((res => {
        console.log("res:", res)
        if (res.code == 0) {
          this.tableData = res.data
          //this.tableData = [{ "id": "2", "visible": true, "sort": 23, "jumpUrl": "weu", "imageUrl": "http://oss.tpyy.xyz/2024-11-10/2476228121371-1855489843753795584.jpg" }]
        }

      }))
    },
    onClosed() {
      console.log("onClosed")
      this.clear()
    },
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = { ...this.form }
          this.request.post("/banner/save", {
            ...params
          }).then((res => {
            console.log("res:", res)
            if (res.code == 0) {
              //保存成功
              this.dialogFormVisible = false
              this.load()
              this.$message.success("保存成功")
            }

          }))
        }
      })
    },
    clear() {
      this.form = {
        id: '',
        visible: false,
        sort: '',
        jumpUrl: '',
        imageUrl: ''
      }
      this.fileList = []
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {
        id: '',
        visible: false,
        sort: '',
        jumpUrl: '',
        imageUrl: ''
      }
      this.fileList = []
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
      this.fileList = [
        {
          status: "success",
          url: this.form.imageUrl
        }
      ]
    },
    del(id) {
      this.request.post("/banner/remove?id=" + id).then(res => {
        if (res.code == 0) {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.jumpUrl = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open("http://localhost:9090/course/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}

.upload-btn i {
  font-size: 12px;
  color: white;
}
</style>
